// RUNS WHEN APPLICATION STARTS
export const FETCH_CONFIG_INIT          = 'FETCH_CONFIG_INIT';
export const FETCH_CONFIG_INIT_START    = 'FETCH_CONFIG_INIT_START';
export const FETCH_CONFIG_INIT_SUCCESS  = 'FETCH_CONFIG_INIT_SUCCESS';
export const FETCH_CONFIG_INIT_FAIL     = 'FETCH_CONFIG_INIT_FAIL';

// RUNS WHEN MOVIES COMPONENT MOUNTS
export const FETCH_MOVIES_START         = 'FETCH_MOVIES_START';
export const FETCH_MOVIES_INIT          = 'FETCH_MOVIES_INIT';
export const FETCH_MOVIES_INIT_SUCCESS  = 'FETCH_MOVIES_INIT_SUCCESS';
export const FETCH_MOVIES_INIT_FAIL     = 'FETCH_MOVIES_INIT_FAIL';

// CHANGE MOVIE LIST
export const CHANGE_MOVIE_LIST          = 'CHANGE_MOVIE_LIST';
export const CHANGE_MOVIE_LIST_START    = 'CHANGE_MOVIE_LIST_START';
export const CHANGE_MOVIE_LIST_SUCCESS  = 'CHANGE_MOVIE_LIST_SUCCESS';
export const CHANGE_MOVIE_LIST_FAIL     = 'CHANGE_MOVIE_LIST_FAIL';

// RUNS WHENEVER MOVIE CAROUSEL CHANGES
export const CHANGE_CAROUSEL_MOVIE        = 'CHANGE_CAROUSEL_MOVIE';
export const CHANGE_CAROUSEL_MOVIE_ARROW  = 'CHANGE_CAROUSEL_MOVIE_ARROW';
export const RESIZE_CAROUSEL_SLIDE        = 'RESIZE_CAROUSEL_SLIDE';

// RUNS WHENEVER CLIENT CLICKS ON A MOVIE
export const GET_MOVIE_DETAILS_START    = 'GET_MOVIE_DETAILS_START';
export const GET_MOVIE_DETAILS          = 'GET_MOVIE_DETAILS';
export const GET_MOVIE_DETAILS_SUCCESS  = 'GET_MOVIE_DETAILS_SUCCESS';
export const GET_MOVIE_DETAILS_FAIL     = 'GET_MOVIE_DETAILS_FAIL';
export const CLEAR_MOVIE_DETAILS        = 'CLEAR_MOVIE_DETAILS';
export const RESET_TRANSLATE_MOVIE      = 'RESET_TRANSLATE_MOVIE';

// RUNS WHEN TV COMPONENT MOUNTS
export const FETCH_TV_START         = 'FETCH_TV_START';
export const FETCH_TV_INIT          = 'FETCH_TV_INIT';
export const FETCH_TV_INIT_SUCCESS  = 'FETCH_TV_INIT_SUCCESS';
export const FETCH_TV_INIT_FAIL     = 'FETCH_TV_INIT_FAIL';

// CHANGE TV LIST
export const CHANGE_TV_LIST          = 'CHANGE_TV_LIST';
export const CHANGE_TV_LIST_START    = 'CHANGE_TV_LIST_START';
export const CHANGE_TV_LIST_SUCCESS  = 'CHANGE_TV_LIST_SUCCESS';
export const CHANGE_TV_LIST_FAIL     = 'CHANGE_TV_LIST_FAIL';

// RUNS WHENEVER TV CAROUSEL CHANGES
export const CHANGE_CAROUSEL_TV        = 'CHANGE_CAROUSEL_TV';
export const CHANGE_CAROUSEL_TV_ARROW  = 'CHANGE_CAROUSEL_TV_ARROW';
export const RESIZE_CAROUSEL_SLIDE_TV  = 'RESIZE_CAROUSEL_SLIDE_TV';

// RUNS WHENEVER CLIENT CLICKS ON A TV
export const GET_TV_DETAILS_START    = 'GET_TV_DETAILS_START';
export const GET_TV_DETAILS          = 'GET_TV_DETAILS';
export const GET_TV_DETAILS_SUCCESS  = 'GET_TV_DETAILS_SUCCESS';
export const GET_TV_DETAILS_FAIL     = 'GET_TV_DETAILS_FAIL';
export const CLEAR_TV_DETAILS        = 'CLEAR_TV_DETAILS';
export const RESET_TRANSLATE_TV      = 'RESET_TRANSLATE_TV';

// SEARCHBAR RESULTS
export const GET_SEARCHBAR_RESULTS_START    = 'GET_SEARCHBAR_RESULTS_START';
export const GET_SEARCHBAR_RESULTS          = 'GET_SEARCHBAR_RESULTS';
export const GET_SEARCHBAR_RESULTS_SUCCESS  = 'GET_SEARCHBAR_RESULTS_SUCCESS';
export const GET_SEARCHBAR_RESULTS_FAIL     = 'GET_SEARCHBAR_RESULTS_FAIL';

// CHANGE SEARCH LIST
export const CHANGE_SEARCH_LIST          = 'CHANGE_SEARCH_LIST';
export const CHANGE_SEARCH_LIST_START    = 'CHANGE_SEARCH_LIST_START';
export const CHANGE_SEARCH_LIST_SUCCESS  = 'CHANGE_SEARCH_LIST_SUCCESS';
export const CHANGE_SEARCH_LIST_FAIL     = 'CHANGE_SEARCH_LIST_FAIL';

// DISCOVER FILTER QUERIES
export const GET_DISCOVER_INIT            = 'GET_DISCOVER_INIT';
export const GET_DISCOVER_INIT_START      = 'GET_DISCOVER_INIT_START';
export const GET_DISCOVER_INIT_FAIL       = 'GET_DISCOVER_INIT_FAIL';

export const GET_DISCOVER_RESULTS_START   = 'GET_DISCOVER_RESULTS_START';
export const GET_DISCOVER_RESULTS         = 'GET_DISCOVER_RESULTS';
export const GET_DISCOVER_RESULTS_SUCCESS = 'GET_DISCOVER_RESULTS_SUCCESS';
export const GET_DISCOVER_RESULTS_FAIL    = 'GET_DISCOVER_RESULTS_FAIL';

export const CHANGE_DISCOVER_LIST         = 'CHANGE_DISCOVER_LIST';
export const CHANGE_DISCOVER_LIST_START   = 'CHANGE_DISCOVER_LIST_START';
export const CHANGE_DISCOVER_LIST_SUCCESS = 'CHANGE_DISCOVER_LIST_SUCCESS';
export const CHANGE_DISCOVER_LIST_FAIL    = 'CHANGE_DISCOVER_LIST_FAIL';

// LOGIN PAGE
export const GET_LOGIN_INIT         = 'GET_LOGIN_INIT';
export const GET_LOGIN_INIT_START   = 'GET_LOGIN_INIT_START';
export const GET_LOGIN_INIT_SUCCESS = 'GET_LOGIN_INIT_SUCCESS';
export const GET_LOGIN_INIT_FAIL    = 'GET_LOGIN_INIT_FAIL';

export const LOGIN            = 'LOGIN';
export const LOGIN_START      = 'LOGIN_START';
export const LOGIN_REQUESTING = 'LOGIN_REQUESTING';
export const LOGIN_APPROVED   = 'LOGIN_APPROVED';
export const LOGIN_UNAPPROVED = 'LOGIN_UNAPPROVED';
export const LOGIN_SUCCESS    = 'LOGIN_SUCCESS';
export const LOGIN_FAIL       = 'LOGIN_FAIL';

export const LOGOUT         = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL    = 'LOGOUT_FAIL';

// PROFILE
export const GET_PROFILE_INIT         = 'GET_PROFILE_INIT';
export const GET_PROFILE_INIT_START   = 'GET_PROFILE_INIT_START';
export const GET_PROFILE_INIT_SUCCESS = 'GET_PROFILE_INIT_SUCCESS';
export const GET_PROFILE_INIT_FAIL    = 'GET_PROFILE_INIT_FAIL';

export const UPDATE_PROFILE         = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL    = 'UPDATE_PROFILE_FAIL';
export const CLEAR_PROFILE_DATA     = 'CLEAR_PROFILE_DATA';

export const SUBMIT_RATING            = 'SUBMIT_RATING';
export const FAVORITE_FILM            = 'FAVORITE_FILM';
export const FAV_OR_RATE_SUCCESS      = 'FAV_OR_RATE_SUCCESS';
export const FAV_OR_RATE_FAIL         = 'FAV_OR_RATE_FAIL';
export const CLEAR_VALIDATION_MESSAGE = 'CLEAR_VALIDATION_MESSAGE';

// PEOPLE
export const GET_PERSON_DETAILS         = 'GET_PERSON_DETAILS';
export const GET_PERSON_DETAILS_START   = 'GET_PERSON_DETAILS_START';
export const GET_PERSON_DETAILS_SUCCESS = 'GET_PERSON_DETAILS_SUCCESS';
export const GET_PERSON_DETAILS_FAIL    = 'GET_PERSON_DETAILS_FAIL';
export const CLEAR_PERSON_DETAILS       = 'CLEAR_PERSON_DETAILS';